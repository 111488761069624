import {Component, OnInit} from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
import { faSave, faFileAlt, faTrash, faInfoCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from './../../services/auth.service';
import { ProductInfoComponent } from '../product-info/product-info.component';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { EngineService } from './../../engine/engine.service'; //TODO: REMOVE ON production
import { AppService } from 'src/app/services/app-service.service';
import { TranslateService } from '@ngx-translate/core'


@Component({
  selector: 'app-ui-infobar-top',
  templateUrl: './ui-infobar-top.component.html',
  styleUrls: ['./ui-infobar-top.component.scss']
})
export class UiInfobarTopComponent implements OnInit {
  faSave = faSave
  faFileAlt = faFileAlt
  faTrash = faTrash
  faInfoCircle = faInfoCircle
  faSignOutAlt = faSignOutAlt
  currentPage
  isLoggedIn
  userDetails
  ctxMenu
  isMobile = false

  public constructor(private router: Router, private authService:AuthService, public engServ: EngineService, private modalService: NgbModal,
    public appService: AppService, public translateService: TranslateService) {
    this.currentPage = router.url
    this.engServ.contextMenu.subscribe(
      (res)=>{
        this.ctxMenu = res
      }
    )

    this.authService.isLoggedIn().subscribe(
      (res)=>{
          this.isLoggedIn = res
        }
    )
    this.authService.getUserData().subscribe(
      (r)=>{
        this.userDetails = r
      }
    )
    let agent = navigator.userAgent;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(agent)){
      this.isMobile = true
    }
  }

  public ngOnInit(): void {
  }

  changeLanguage(){
    this.translateService.use(this.appService.currentLanguage)
  }

  logout(){
    this.authService.logout()
  }

  deleteObject(){
    console.log('[Ui InforBar top component] delelete object')
    this.engServ.deleteObject()
  }

  saveProject(){
    this.engServ.saveProject()
  }

  showProductInfo(){
    this.modalService.open(ProductInfoComponent, {ariaLabelledBy: 'modal-basic-title'}).result
    .then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
