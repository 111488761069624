<div class="ui-wrapper">
    <div class="row">
      <div class="col-md-12">
        <app-ui-infobar-top></app-ui-infobar-top>
      </div>
    </div>

    <div class="row">
      <div class="col-3 col-md-2">
        <app-ui-sidebar-left></app-ui-sidebar-left>
      </div>
      <div class="col-9 col-md-10">
        <app-room-config></app-room-config>

        <br><br>
        <div class="row">
          <div class="col-4" style="text-align: center;">
          </div>
          <div class="col-4" style="text-align: center;">
          </div>
          <div class="col-4" style="text-align: center;">
            <button routerLink="/pas2" class="btn btn-arrow-right">
              {{'Pasul urmator' | translate}}
              <img src="/assets/imgs/arrow-right.png">
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="row footer">
        <div class="col-md-12">
          <app-ui-infobar-bottom></app-ui-infobar-bottom>
        </div>
    </div>


</div>
