// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  version: require('../../package.json').version,
  firebase: {
    apiKey: "AIzaSyDvr-SjXQLFr2hpHS4hNa1KKUd4qz2Fd3E",
    authDomain: "fotbal-53997.firebaseapp.com",
    databaseURL: "https://fotbal-53997.firebaseio.com",
    projectId: "fotbal-53997",
    storageBucket: "fotbal-53997.appspot.com",
    messagingSenderId: "11592230209",
    appId: "1:11592230209:web:2c3baa15995caa02386dc0",
    measurementId: "G-KYHLJWRTCP"
  }
};
