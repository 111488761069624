<div class="plan-container">
    <div class="row">
        <div class="wall-horizontal" [ngStyle]="{'background-image': 'url(' + '/assets/textures/pereti/'+ appService.wallColor + ')'}">
            <input [(ngModel)]="appService.roomWidth" (change)="changeSize()"> cm
        </div>
    </div>
    <div class="row">
        <div class="wall-vertical" [ngStyle]="{'background-image': 'url(' + '/assets/textures/pereti/'+ appService.wallColor + ')'}">
            <div id="input-left">
                <input [(ngModel)]="appService.roomLength"> cm
            </div>
        </div>
        <div class="center-space" [ngStyle]="{'background-image': 'url(' + '/assets/textures/'+ appService.floorTexture + ')'}">
        </div>
        <div class="wall-vertical" [ngStyle]="{'background-image': 'url(' + '/assets/textures/pereti/'+ appService.wallColor + ')'}">
            <div id="input-right">
                <input [(ngModel)]="appService.roomLength"> cm
            </div>
        </div>
    </div>
    <div class="row">
        <div class="wall-horizontal" [ngStyle]="{'background-image': 'url(' + '/assets/textures/pereti/'+ appService.wallColor + ')'}">
                <input [(ngModel)]="appService.roomWidth"> cm
        </div>
    </div>

</div>
