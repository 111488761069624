import {Component, OnInit} from '@angular/core';
import { AppService } from './../../services/app-service.service';

@Component({
  selector: 'app-ui-infobar-bottom',
  templateUrl: './ui-infobar-bottom.component.html',
  styleUrls: ['./ui-infobar-bottom.component.scss']
})
export class UiInfobarBottomComponent implements OnInit {

  public constructor( public appService: AppService) {
  }

  public ngOnInit(): void {
  }

}
