import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pas3',
  templateUrl: './pas3.component.html',
  styleUrls: ['./pas3.component.scss']
})
export class Pas3Component implements OnInit {

  public constructor(private httpClient: HttpClient, ) {
  }
  manere
  alteAccesorii
  frigidere
  aragaze
  hote

  public ngOnInit(): void {
    this.httpClient.get("/assets/data/manere.json").subscribe(data =>{
      this.manere = data;
      console.log('manere', this.manere)
    })
    this.httpClient.get("/assets/data/alte_accesorii.json").subscribe(data =>{
      this.alteAccesorii = data;
    })
    this.httpClient.get("/assets/data/frigidere.json").subscribe(data =>{
      this.frigidere = data;
    })
    this.httpClient.get("/assets/data/aragaze.json").subscribe(data =>{
      this.aragaze = data;
    })
    this.httpClient.get("/assets/data/hote.json").subscribe(data =>{
      this.hote = data;
    })


  }

}
