<br>
<h2 class="options-title">{{'Optiuni podea' | translate}}</h2>
<div>
    <ng-container *ngFor="let podea of optiuni_podea" >
        <div class="col-md-4 option-sample"
              style="background-image: url('/assets/textures/{{podea.texture_url}}'); "
              (click)="changeFloorTexture(podea.texture_url)">
        </div>
    </ng-container>
</div>

<div style="height: 14vh;">

</div>

<h2 class="options-title">{{'Optiuni pereti' | translate}}</h2>
<div>
    <ng-container *ngFor="let perete of optiuni_pereti" >
        <div class="col-md-4 option-sample"
            style="background-image: url('/assets/textures/pereti/{{perete.texture}}'); "
            (click)="changeWallColor(perete.texture)">
          </div>
    </ng-container>
</div>
