import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from './../../services/auth.service';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {
  user
  projectsList = []
  constructor(public authService: AuthService, public afStorage: AngularFireStorage) { }

  ngOnInit(): void {
    let user = this.authService.getUser()
    const folderRef = this.afStorage.ref('/projects/'+user.uid+'/')
    folderRef.listAll().subscribe(
      (res)=>{
        this.projectsList = res.items
      }
    )
  }

}
