import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { parse } from 'node-html-parser'
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import {AppComponent} from './app.component';
import {EngineComponent} from './engine/engine.component';
import {UiInfobarBottomComponent} from './ui/ui-infobar-bottom/ui-infobar-bottom.component';
import {UiInfobarTopComponent} from './ui/ui-infobar-top/ui-infobar-top.component';
import {Pas1SidebarLeftComponent} from './pas1/pas1-sidebar-left/pas1-sidebar-left.component';
import { Pas2SidebarLeftComponent } from './pas2/pas2-sidebar-left/pas2-sidebar-left.component';
import {UiSidebarRightComponent} from './ui/ui-sidebar-right/ui-sidebar-right.component';
import {UiComponent} from './ui/ui.component';
import { AppRoutingModule } from './app-routing.module';
import { Pas1Component } from './pas1/pas1.component';
import { Pas2Component } from './pas2/pas2.component';
import { Pas3Component } from './pas3/pas3.component';
import { Pas4Component } from './pas4/pas4.component';
import { RoomConfigComponent } from './pas1/room-config/room-config.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './pas1/login/login.component';
import { RegisterComponent } from './pas1/register/register.component';
import { ProjectsListComponent } from './ui/projects-list/projects-list.component';
import { ProductInfoComponent } from './ui/product-info/product-info.component'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    EngineComponent,
    UiComponent,
    UiInfobarBottomComponent,
    UiInfobarTopComponent,
    Pas1SidebarLeftComponent,
    Pas2SidebarLeftComponent,
    UiSidebarRightComponent,
    Pas1Component,
    Pas2Component,
    Pas3Component,
    Pas4Component,
    RoomConfigComponent,
    LoginComponent,
    RegisterComponent,
    ProjectsListComponent,
    ProductInfoComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
