<div class="options-title">
    {{'Optiuni generale' | translate}}
    <fa-icon [icon]="faArrowCircleUp" class="arrow-icon" *ngIf="panels.generale" (click)="togglePanel('generale')"></fa-icon>
    <fa-icon [icon]="faArrowCircleDown" class="arrow-icon" *ngIf="!panels.generale" (click)="togglePanel('generale')"></fa-icon>
</div>
<div  class="fullwidth objects-list" [ngClass]="{collapsed: panels.generale == false}">
    <p>{{'Culoare fronturi' | translate}}</p>
    <div class="row">
      <ng-container *ngFor="let culoare of culoriFronturi" >
          <div class="col-6 col-md-3 option-sample"
                  style="background-image: url('/assets/textures/{{culoare.texture_url}}'); "
                  (click)="selectCuloareFronturi(culoare)"
                  [ngClass]="{'culoare-selected':culoare.id === engServ.culoareFrontSelectata.id}"
          >
          </div>
      </ng-container>
    </div>

    <p>{{'Culoare corpuri' | translate}}</p>
    <div class="row">
      <ng-container *ngFor="let culoare of culoriCorpuri" >
          <div class="col-6 col-md-3 option-sample"
              style="background-image: url('/assets/textures/{{culoare.texture_url}}'); "
              (click)="selectCuloareCorpuri(culoare)"
              [ngClass]="{'culoare-selected':culoare.id === engServ.culoareCorpSelectata.id}"
          >
          </div>
      </ng-container>
    </div>

    <p>{{'Materiale blat' | translate}}</p>
    <div class="row">
      <ng-container *ngFor="let culoare of culoriBlat" >
          <div class="col-6 col-md-3 option-sample" style="background-image: url('/assets/textures/{{culoare.thumbnail}}'); "
              (click)="selectCuloareBlat(culoare)"
              [ngClass]="{'culoare-selected':culoare.id === engServ.culoareBlatSelectata.id}"
          >
          </div>
      </ng-container>
    </div>



</div>

<div class="options-title">
  {{'Manere' | translate}}
  <fa-icon [icon]="faArrowCircleUp" class="arrow-icon" *ngIf="panels.manere" (click)="togglePanel('manere')"></fa-icon>
  <fa-icon [icon]="faArrowCircleDown" class="arrow-icon" *ngIf="!panels.manere" (click)="togglePanel('manere')"></fa-icon>
</div>
<div class="fullwidth objects-list" [ngClass]="{collapsed: panels.manere== false}">
  <ng-container *ngFor="let maner of manere | keyvalue">
      <ng-template #imgBazaPreview>
        <img style="max-height: 300px;" src="/assets/accesorii/manere/{{maner.value.preview}}"/>
      </ng-template>

      <div class="corp"
        (click)="selectManer(maner.value)"
        [ngClass]="{'culoare-selected':maner.value.id === engServ.manerSelectat?.id}"
        placement="right"
        [ngbPopover]="imgBazaPreview"
        triggers="mouseenter:mouseleave:mouseover"
        popoverTitle="Preview"

      >
          <div class="corp-preview">
              <img src="/assets/accesorii/manere/{{maner.value.preview}}">
          </div>
      </div>
  </ng-container>
</div>


<div class="options-title">
    {{'Corpuri suspendate' | translate}}
    <fa-icon [icon]="faArrowCircleUp" class="arrow-icon" *ngIf="panels.suspendate" (click)="togglePanel('suspendate')"></fa-icon>
    <fa-icon [icon]="faArrowCircleDown" class="arrow-icon" *ngIf="!panels.suspendate" (click)="togglePanel('suspendate')"></fa-icon>
</div>
<div class="fullwidth objects-list" [ngClass]="{collapsed: panels.suspendate== false}">
    <ng-container *ngFor="let corp of corpuriSuspendate | keyvalue">
        <ng-template #imgBazaPreview>
          <img style="max-height: 300px;" src="/assets/corpuri/fullimgs/{{corp.value.preview}}"/>
        </ng-template>

        <div class="corp" (click)="addObject(corp.value.id, 'suspendat')"
          placement="right"
          [ngbPopover]="imgBazaPreview"
          triggers="mouseenter:mouseleave:mouseover"
          popoverTitle="Preview"
        >
            <div class="corp-preview">
                <img src="/assets/corpuri/preview/{{corp.value.preview}}">
            </div>
            <div class="corp-detalii">
                latime: {{corp.value.latime}} <br>
                inaltime: {{corp.value.inaltime}} <br>
                adancime: {{corp.value.adancime}} <br>
            </div>
        </div>
    </ng-container>
</div>

<h2 class="options-title">
    {{'Corpuri baza' | translate}}
    <fa-icon [icon]="faArrowCircleUp" class="arrow-icon" *ngIf="panels.baza" (click)="togglePanel('baza')"></fa-icon>
    <fa-icon [icon]="faArrowCircleDown" class="arrow-icon" *ngIf="!panels.baza" (click)="togglePanel('baza')"></fa-icon>
</h2>
<div class="fullwidth objects-list" [ngClass]="{collapsed: panels.baza == false}" >
    <ng-container *ngFor="let corpBaza of corpuriBaza | keyvalue" class="col-md-4">
        <ng-template #imgBazaPreview>
          <img style="max-height: 300px;" src="/assets/corpuri/fullimgs/{{corpBaza.value.preview}}"/>
        </ng-template>

        <button class="corp"
          (click)="addObject(corpBaza.value.id, 'baza')"
          placement="right"
          [ngbPopover]="imgBazaPreview"
          triggers="mouseenter:mouseleave:mouseover"
          popoverTitle="Preview"
          >
            <div class="corp-preview">
                <img src="/assets/corpuri/preview/{{corpBaza.value.preview}}">
            </div>
            <div class="corp-detalii">
                latime: {{corpBaza.value.latime}} <br>
                inaltime: {{corpBaza.value.inaltime}} <br>
                adancime: {{corpBaza.value.adancime}} <br>
            </div>
        </button>
    </ng-container>
</div>
