<div class="ui-wrapper">
    <div class="row">
      <div class="col-md-12">
        <app-ui-infobar-top></app-ui-infobar-top>
      </div>
    </div>

    <div class="row">
      <div class="col-3 col-md-2 pas2-sidebar">
        <app-ui-sidebar-pas2-left></app-ui-sidebar-pas2-left>
      </div>
      <div class="col-9 col-md-10">
        <app-engine></app-engine>
      </div>
    </div>

    <div class="row footer">
      <div class="col-12">
        <app-ui-infobar-bottom></app-ui-infobar-bottom>
      </div>
    </div>
</div>
