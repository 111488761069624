import { Observable, Subject } from 'rxjs';
import * as THREE from 'three';
// import * as THREE from 'three/build/three.module.js';
import * as TWEEN from '@tweenjs/tween.js'
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { ARButton } from './../tools/ARButton.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { DragControls } from './../tools/DragControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import {ElementRef, Injectable, NgZone, OnDestroy, HostListener, ViewChild} from '@angular/core';
import { AppService } from './../services/app-service.service';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

@Injectable({providedIn: 'root'})
export class EngineService implements OnDestroy {
  private canvas: HTMLCanvasElement;
  // private renderer: THREE.WebGLRenderer;
  private renderer = new THREE.WebGLRenderer({antialias: false})
  private labelRenderer = new CSS2DRenderer();
  public camera: THREE.PerspectiveCamera;
  public scene: THREE.Scene;
  private light: THREE.AmbientLight;
  // private light: THREE.DirectionalLight;
  public transformControl: TransformControls
  private orbitControls: OrbitControls

  private cube: THREE.Mesh;

  private frameId: number = null;

  raycaster = new THREE.Raycaster()
  mouse = new THREE.Vector2();
  objLoader = new OBJLoader2()
  gltfLoader = new GLTFLoader()
  cameraZoom = 1
  corpuri = []
  corpuriSuspendate = []
  corpuriBaza = []
  manere = []
  countertop
  walls
  culoareFrontSelectata
  culoareCorpSelectata
  culoareBlatSelectata
  manerSelectat
  contextMenu: Subject<any> = new Subject()
  selectedObject
  dragControls
  currentY
  sceneObjectsPositions = {}
  helpCoords = {}
  controller

  public constructor(private ngZone: NgZone, public appService:AppService) {
    this.render = this.render.bind(this);

    // this.dragControls =  new DragControls( this.draggableObjects, this.camera, this.renderer.domElement );
  }

  onCanvasClickDown($event){
    // log mouse position to check it against mouse up
    this.mouse = $event

    let intersects;
    let localMouse = new THREE.Vector2();
    localMouse.x = ( $event.layerX / this.renderer.domElement.clientWidth ) * 2 - 1;
    localMouse.y = - ( $event.layerY / this.renderer.domElement.clientHeight ) * 2 + 1;
    this.raycaster.setFromCamera( localMouse, this.camera );

    //check if element is clicked
    let clickedObject
    intersects = this.raycaster.intersectObjects( this.scene.children, true );
    if(!intersects.length){ return }
    else{
      let clickedAnObject = false
      intersects.forEach(element => {
        if(element.object.name.includes('_')){
          this.selectedObject = element.object.parent
          clickedAnObject = true
          // const contextMenu = this.createContextMenu()
          // const clickedObjectSize = this.getObjectSize(clickedObject)
          // this.contextMenu.position.setX(clickedObjectSize.x * 2 )
          // this.contextMenu.position.setY(clickedObjectSize.y / 2 )
          // this.contextMenu.position.setZ(clickedObjectSize.z + this.contextMenuSize.z)

          // console.log('onCanvasClickDown', $event)
          return
        }
      })
      this.contextMenu.next({status: clickedAnObject, position: {x: $event.clientX + 40, y: $event.clientY }})
    }
  }

  outlineObject(obj){
    obj.children.forEach(element => {
      if(element && element.material){
              // element.material.color.set( 0xe37691 )
              element.material.transparent = true
              element.material.opacity = 0.5
      }
    })
  }

  removeOutlineObject(obj){
    obj.children.forEach(element => {
      if(element && element.material){
        // element.material.color.set( 0xffffff )
        element.material.transparent = false
        element.material.opacity = 1
      }
    })
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  onMouseMove( event ) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components

    this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

  }
  // onClick(event){
  //   const mouse3D = new THREE.Vector3( ( event.clientX / window.innerWidth ) * 2 - 1,
  //                                   -( event.clientY / window.innerHeight ) * 2 + 1,
  //                                   0.5 )
  //   let raycaster =  new THREE.Raycaster()
  //   raycaster.setFromCamera( mouse3D, camera )
  //   console.log('raycaster', raycaster)
  // }

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,    // transparent background
      antialias: true // smooth edges
    })
    this.renderer.setSize(window.innerWidth * 0.82, window.innerHeight * 0.7);
    this.renderer.xr.enabled = true;

    // create the scene
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      75, window.innerWidth / window.innerHeight, 1, 2000
    );
    this.camera.position.z = 0;
    this.camera.position.y = this.appService.roomHeight * 0.4 ;
    this.camera.position.x = this.appService.roomWidth * 0.84;
    this.camera.rotation.y = 1.57
        this.camera.lookAt(0, this.appService.roomHeight*0.4 ,0)
    this.scene.add(this.camera);


    // mutam camera catre fata
    // this.setWallsVisibleExcept(1)
    // this.moveCamera(new THREE.Vector3( 0, 0, 0 - this.appService.roomWidth * 0.8), 0 )

    // soft white light
    this.light = new THREE.AmbientLight(0xf5f4ef);
    // this.light = new THREE.DirectionalLight( 0xffffff, 1 )
    // this.light.position.y = this.appService.roomHeight;
    // this.light.castShadow = true;
    this.scene.add(this.light);

    // const geometry = new THREE.BoxGeometry(1, 1, 1);
    // const material = new THREE.MeshBasicMaterial({color: 0x00ff00});
    // this.cube = new THREE.Mesh(geometry, material);
    // this.scene.add(this.cube);

    // const planeSize = 100;

    // const loader = new THREE.TextureLoader();
    // const texture = loader.load('https://threejsfundamentals.org/threejs/resources/images/checker.png');
    // texture.wrapS = THREE.RepeatWrapping;
    // texture.wrapT = THREE.RepeatWrapping;
    // texture.magFilter = THREE.NearestFilter;
    // const repeats = planeSize / 2;
    // texture.repeat.set(repeats, repeats);

    // const planeGeo = new THREE.PlaneGeometry(planeSize, planeSize);
    // const planeMat = new THREE.MeshPhongMaterial({
    //   map: texture,
    //   side: THREE.DoubleSide,
    // });
    // const mesh = new THREE.Mesh(planeGeo, planeMat);
    // mesh.rotation.x = Math.PI * -.5;
    // this.scene.add(mesh);


    // this.addAxesHelper()
    this.createWalls()
    this.setWallsVisibleExcept(4)
    // this.createFurnitureSet()
    this.initDragControls()
    // this.initTransformControls()
    // this.initOrbitControls()
    // gl.makeXRCompatible().then(() => {
    //   document.getElementById('bottom-controls').appendChild( ARButton.createButton( this.renderer, { requiredFeatures: [ 'hit-test' ] } ) )
    // })
    document.getElementById('bottom-controls').appendChild( ARButton.createButton( this.renderer, { requiredFeatures: [ 'hit-test' ] } ) )
    this.controller = this.renderer.xr.getController( 0 );
    // console.log('this.controller', this.controller)
    // this.controller.addEventListener( 'select', this.onSelect() );
    // this.scene.add( this.controller );


  }

  onSelect() {
    const geometry = new THREE.CylinderGeometry( 0, 0.05, 0.2, 32 ).rotateX( Math.PI / 2 )
    const material = new THREE.MeshPhongMaterial( { color: 0xffffff * Math.random() } );
    const mesh = new THREE.Mesh( geometry, material );
    mesh.position.set( 0, 0, - 0.3 ).applyMatrix4( this.controller.matrixWorld );
    mesh.quaternion.setFromRotationMatrix( this.controller.matrixWorld );
    this.scene.add( mesh );

  }


  initTransformControls(){
    const self: EngineService = this
    this.transformControl = new TransformControls( this.camera, this.renderer.domElement );
    this.transformControl.addEventListener( 'change', this.render )

    // this.transformControl.addEventListener( 'dragging-changed', function ( event ) {
    //       self.orbitControls.enabled = ! event.value
    // } );
    this.scene.add(this.transformControl)
  }

  public initOrbitControls(){
    this.orbitControls = new OrbitControls( this.camera, this.renderer.domElement );
    // controls.listenToKeyEvents( window ); // optional

    //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

    this.orbitControls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    this.orbitControls.dampingFactor = 0.05;

    this.orbitControls.screenSpacePanning = false;

    this.orbitControls.minDistance = 100;
    this.orbitControls.maxDistance = 1000;

    this.orbitControls.maxPolarAngle = Math.PI / 2;


  }

  initDragControls(){
    const self: EngineService = this
    this.dragControls = new DragControls( this.corpuri, this.camera, this.renderer.domElement )
    this.dragControls.transformGroup = true

    this.dragControls.addEventListener( 'dragstart', function ( event ) {
      self.contextMenu.next(false)
      var bbox = new THREE.Box3().setFromObject(event.object);
      this.currentY = event.object.position.y
      self.outlineObject(event.object)
    } );

    this.dragControls.addEventListener( 'drag', function ( event ) {
      event.object.position.y = this.currentY
      self.helpCoords['draggedObjectPosition'] = event.object.position
      self.checkSnap(event.object)
      self.resizeCountertop()
    } );

    this.dragControls.addEventListener( 'dragend', function ( event ) {
      self.contextMenu.next(true)
      self.sceneObjectsPositions[event.object.uuid.position] = event.object.position
      self.removeOutlineObject(event.object)
      self.resizeCountertop()
    } );

  }

  checkSnap(object){
    const self: EngineService = this
    this.helpCoords['distanceTo'] = {}
    let corpuriDeComparat
    if(this.corpuriBaza.includes(object)){
      corpuriDeComparat = this.corpuriBaza
    }
    else{
      corpuriDeComparat = this.corpuriSuspendate
    }
    corpuriDeComparat.forEach(
      (obj, i)=>{
        // this.helpCoords['distanceTo'][obj.name + ' dist']
        const targetObjectSize = self.getObjectSize(obj)
        const distanceToObject = object.position.distanceTo(obj.position)
        const distanceToSnap = (targetObjectSize.z + self.getObjectSize(object).z) / 2
        if(
          (distanceToObject >= distanceToSnap && distanceToObject <= distanceToSnap * 1.3) || // within snapping limits
          (distanceToObject < targetObjectSize.z)                                             // objects overlap
         ){
          if(object.position.z > obj.position.z){ // snap left
            object.position.setZ(obj.position.z + distanceToSnap)
          }
          if(object.position.z < obj.position.z){ // snap right
            object.position.setZ(obj.position.z - distanceToSnap)
          }
        }
      }
    )

    // Wall RIGHT collision
    let wall1 = this.scene.getObjectByName('wall1')
    let distanceToWall1 = wall1.position.z + (self.appService.wallWidth / 2) - object.position.z
    let objectSize = self.getObjectSize(object)
    if(Math.abs(distanceToWall1) < objectSize.z / 2 || object.position.z < wall1.position.z){
      object.position.setZ(wall1.position.z + self.appService.wallWidth + ( objectSize.z / 2 ))
    }
    // Wall LEFT collision
    let wall3 = this.scene.getObjectByName('wall3')
    let distanceToWall3 = wall3.position.z - (self.appService.wallWidth / 2) - object.position.z
    if(Math.abs(distanceToWall3) < objectSize.z / 2 || object.position.z > wall3.position.z){
      object.position.setZ(wall3.position.z - (self.appService.wallWidth / 2) - ( objectSize.z / 2 ))
    }
    this.helpCoords['distanceTo']['wall3 (left)'] = distanceToWall3
    this.helpCoords['distanceTo']['object position'] = object.position.z
    this.helpCoords['distanceTo']['wall position'] = wall3.position.z
    this.helpCoords['distanceTo']['object size'] = objectSize.z
  }

  intersectsObj(obj1, obj2){
    console.log('[Engine service] {intersectsObj} obj1', obj1)
    console.log('[Engine service] {intersectsObj} obj2', obj2)
    const object1 = new THREE.Box3().setFromObject(obj1)
    const object2 = new THREE.Box3().setFromObject(obj2)

    return object1.max.x < object2.min.x || object1.min.x > object2.max.x ||
			object1.max.y < object2.min.y || object1.min.y > object2.max.y ||
			object1.max.z < object2.min.z || object1.min.z > object2.max.z ? false : true;
  }

  createWalls(){
    this.walls = []
    console.log('[Create wall] '+ this.appService.roomWidth + ' / '  + this.appService.roomLength)
    const floorGeometry = new THREE.BoxGeometry(this.appService.roomWidth, this.appService.roomLength, 20);
    const floorMaterial = this.createMaterial(this.appService.floorTexture, 0, 1)
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.rotateX(Math.PI * -.5)
    floor.position.setY(-10)
    floor.name = 'floor'
    this.scene.add(floor);

    this.walls.push(floor)

    const geometry = new THREE.BoxGeometry(this.appService.roomWidth, 272, 20)
    const wallsMaterial = this.createMaterial('pereti/' + this.appService.wallColor, 1, 1)
    const wall1 = new THREE.Mesh(geometry, wallsMaterial)
    wall1.position.setZ(-(this.appService.roomLength / 2)-10)
    wall1.position.setY(this.appService.roomHeight / 2)
    wall1.name = 'wall1'
    this.scene.add(wall1);
    this.walls.push(wall1)

    const geometry2 = new THREE.BoxGeometry(this.appService.roomLength, 272, 20)
    // const wallsMaterial2 =  this.createMaterial('walls.jpg', 1, 1)

    const wall2 = new THREE.Mesh(geometry2, wallsMaterial)
    wall2.position.setZ(0)
    wall2.position.setX((0 - this.appService.roomWidth / 2)+10)
    wall2.position.setY(this.appService.roomHeight / 2)
    wall2.rotation.y = this.degrees_to_radians(90)
    wall2.name = 'wall2'
    this.scene.add(wall2);
    this.walls.push(wall2)

    const geometry3 = new THREE.BoxGeometry(this.appService.roomWidth, 272, 20);
    // const wallsMaterial3 =  this.createMaterial('walls.jpg', 1, 1)
    // const wallsMaterial3 = this.createMaterial('test/wall3.jpg', 1, 1)
    const cube3 = new THREE.Mesh(geometry3, wallsMaterial)
    cube3.position.setZ((this.appService.roomLength / 2)+10)
    cube3.position.setY(this.appService.roomHeight / 2)
    cube3.name = 'wall3'
    this.scene.add(cube3);
    this.walls.push(cube3)

    const geometry4 = new THREE.BoxGeometry(this.appService.roomLength, 272, 20)
    // const wallsMaterial4 = this.createMaterial('walls.jpg', 1, 1)
    const wallsMaterial4 = this.createMaterial('test/wall4.jpg', 1, 1)
    const cube4 = new THREE.Mesh(geometry4, wallsMaterial4)
    cube4.position.setZ(0)
    cube4.position.setX((this.appService.roomWidth / 2)-10)
    cube4.position.setY(this.appService.roomHeight / 2)
    cube4.rotation.y = this.degrees_to_radians(90)
    cube4.name = 'wall4'
    this.scene.add(cube4);
    this.walls.push(cube4)

  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        // this.initOrbitControls()

      } else {
        window.addEventListener('DOMContentLoaded', () => {
          // this.initControls()

        });
      }
      // this.updateWallTransparency_simple()
      this.render();
      window.addEventListener('resize', () => {
        this.resize();
      })
    })
  }

  public render(): void {
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });
    TWEEN.update();
    this.renderer.render(this.scene, this.camera);
    this.labelRenderer.render(this.scene, this.camera);

  }

  public resize(): void {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }



  public createMaterial(textura, reflexie, opacitate){
    var texture = new THREE.TextureLoader().load( '/assets/textures/'  + textura  );
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    let newMaterial = new THREE.MeshLambertMaterial( { map: texture, reflectivity: reflexie, opacity: opacitate} );

    return newMaterial
  }



  private addAxesHelper(){
    const axesHelper = new THREE.AxesHelper( 150 );
    axesHelper.position.x = 0
    axesHelper.position.y = 0
    axesHelper.position.z = 0
    this.scene.add( axesHelper );
  }

  public loadObject(obj, pos){
    const self: EngineService = this
    const inaltimeCorpuriSuspendate = self.appService.inaltimeCorpuriSuspendate
    let objLoader = new OBJLoader2()
    objLoader.setPath( '/assets/corpuri/')
    console.log('Loading obj', obj)
    objLoader.load( '/assets/corpuri/'+obj.model, function ( corp ) {
      corp.name = self.generateNewObjectName(pos)

      // let boundingBox = new THREE.Box3().setFromObject(corp)
      // let objectSize = new THREE.Vector3
      // boundingBox.getSize(objectSize)
      let objectSize = self.getObjectSize(corp)

      self.corpuri.push(corp) // corpurile draggable

      let lastObject
      if(pos === 'suspendat'){
        if(self.corpuriSuspendate.length > 0){
          // lastObject = self.corpuriSuspendate[self.corpuriSuspendate.length -1]
          lastObject = self.getMostRightObject('suspendat')
          let wall1 = self.scene.getObjectByName('wall1')
  console.log('Last object', lastObject)
          // let distanceToWall = lastObject.position.z - wall1.position.z
          // if(distanceToWall < objectSize.z){
          //   alert('Obiectul nu are suficient spatiu! (' +distanceToWall + ')' )
          //   return
          // }
          // else{
          //   console.log('Obiectul are loc')
          // }

          let lastObjectSize = self.getObjectSize(lastObject)
          corp.rotation.set(lastObject.rotation.x, lastObject.rotation.y, lastObject.rotation.z)
          console.log('[EngineSevice] {loadObject} lastObject position', lastObject.position)
          corp.position.set(lastObject.position.x, lastObject.position.y, lastObject.position.z - (lastObjectSize.z / 2) - (objectSize.z / 2) )

        }
        else{
          lastObject = self.scene.getObjectByName('wall2')
          corp.rotation.set(lastObject.rotation.x, lastObject.rotation.y, lastObject.rotation.z)
          corp.position.set(lastObject.position.x + (objectSize.x / 2),
                              self.appService.inaltimeCorpuriSuspendate ,
                              (self.appService.roomLength / 2) - (objectSize.z / 2) - (self.appService.wallWidth / 2) )
        }
        self.corpuriSuspendate.push(corp)
      }
      // CORPURI BAZA
      else{
        if(self.corpuriBaza.length > 0){
          // lastObject = self.corpuriBaza[self.corpuriBaza.length -1]
          lastObject = self.getMostRightObject('baza')
          let wall1 = self.scene.getObjectByName('wall1')
          let distanceToWall = lastObject.position.z - wall1.position.z
          if(distanceToWall < objectSize.z){
            alert('Obiectul nu are suficient spatiu! (' +distanceToWall + ')' )
            return
          }
          else{
            console.log('Obiectul are loc')
          }
          let lastObjectSize = self.getObjectSize(lastObject)
          corp.rotation.set(lastObject.rotation.x, lastObject.rotation.y, lastObject.rotation.z)

          console.log('[EngineSevice] {loadObject} lastObject name', lastObject.name)
          console.log('[EngineSevice] {loadObject} lastObject size', lastObjectSize)
          corp.position.set(lastObject.position.x, lastObject.position.y, lastObject.position.z - (lastObjectSize.z / 2) - (objectSize.z / 2) )
          console.log('[EngineSevice] {loadObject} new object  position', corp.position)

          self.corpuriBaza.push(corp)
          console.log('self.corpuriBaza', self.corpuriBaza.length)

          self.resizeCountertop()
        }
        else{
          lastObject = self.scene.getObjectByName('wall2')
          corp.rotation.set(lastObject.rotation.x, lastObject.rotation.y, lastObject.rotation.z)
          corp.position.setX(lastObject.position.x + objectSize.x + 1)
          corp.position.setZ((self.appService.roomLength / 2) - (objectSize.z / 2) + (self.appService.wallWidth / 2) )
          self.corpuriBaza.push(corp)
          self.createCountertop()
        }
      }
      console.log('[EngineSevice] {loadObject} corpuri Baza', self.corpuriBaza)

      console.log('[Engine Service] Object loaded', corp)
      for (let i = 0; i < corp.children.length; i++) {
          let el = corp.children[i]
          if (el instanceof THREE.Mesh && (el.name.includes("_usa") || el.name.includes("_front") )) {
            el.material = self.createMaterial(self.culoareFrontSelectata.texture_url, 0.1, 1)
            // INCARCA MANER
            let manerLoader = new OBJLoader2()
            manerLoader.setPath( '/assets/accesorii/manere/')
            manerLoader.load( '/assets/accesorii/manere/' + self.manerSelectat.model,
              ( maner ) => {
                maner.name = self.generateNewObjectName('maner')
                maner.children.forEach(
                  (manerChild)=>{
                    if (manerChild instanceof THREE.Mesh){
                      manerChild.material = self.createMaterial('silver-metal.jpg', 0.1, 1)
                    }
                  }
                )
                if ( el instanceof THREE.Mesh ){
                  let p = self.getManerPosition(el, pos)
                  let s = self.getObjectSize(el)
                maner.position.set(p.x, p.y, p.z + s.x)
                corp.add( maner )
                }
              }
            )
            // END INCARCA MANER
          }

          else if (el instanceof THREE.Mesh && el.name.includes("_sticla")) {
            const mat = new THREE.MeshBasicMaterial({color: 0xe9fffc});
            mat.transparent = true
            mat.opacity = 0.7
            el.material = mat
          }
          else if (el instanceof THREE.Mesh){
            el.material = self.createMaterial(self.culoareCorpSelectata.texture_url, 0.1, 1);
          }
      }
      self.sceneObjectsPositions[corp.uuid] = {location: pos, position: corp.position}
      self.scene.add( corp );

      },
      function ( xhr ) {
        // console.log('[Engine Service] {Load object} percentComplete',  Math.round(xhr.loaded / xhr.total * 100))

      },
      function ( error ) {
        console.log( 'An error happened' , error);
      });
  }

  public getManerPosition(el: THREE.Mesh, position):THREE.Vector3{
    el.geometry.computeBoundingBox();
    var center = new THREE.Vector3();
    el.geometry.boundingBox.getCenter(center);
    el.geometry.center();
    el.position.copy(center)

    let elSize = this.getObjectSize(el)
    switch (position) {
      case 'baza':
        center.setY( (center.y + (elSize.y / 2) ) - 5)
        break;

      case 'suspendat':
        center.setY( (center.y - (elSize.y / 2) ) + 5)
        break;

      default:
        break;
    }

    return center
  }

  getMostRightObject(pos){
    let obiecte

    if(pos == 'baza'){
      obiecte = this.corpuriBaza
    }
    else{
      obiecte = this.corpuriSuspendate
    }
    let celMaiDreapta = obiecte[0]
    let maxRight = obiecte[0].position.z
    obiecte.forEach(element => {
      console.log(maxRight + ' vs ' + element.position.z)
      if(maxRight > element.position.z){
        maxRight = element.position.z
        celMaiDreapta = element
        console.log('maxRight ' + element.name)
      }
    })
    return celMaiDreapta
  }

  getMostLeftObject(pos){
    let obiecte

    if(pos == 'baza'){
      obiecte = this.corpuriBaza
    }
    else{
      obiecte = this.corpuriSuspendate
    }
    let celMaiStanga = obiecte[0]
    let maxLeft = obiecte[0].position.z
    obiecte.forEach(element => {
      console.log(maxLeft + ' vs ' + element.position.z)
      if(maxLeft < element.position.z){
        maxLeft = element.position.z
        celMaiStanga = element
        console.log('maxLeft ' + element.name)
      }
    })
    return celMaiStanga
  }

  createCountertop(){
    const corpSize = this.getObjectSize(this.corpuriBaza[0])
    const geometry = new THREE.BoxGeometry(corpSize.x + 10, 5, corpSize.z)
    const countertopMaterial =  this.createMaterial(this.culoareBlatSelectata.texture_url, 1, 1)
    this.countertop = new THREE.Mesh(geometry, countertopMaterial)
    this.countertop.name = 'countertop'
    this.countertop.position.set(this.corpuriBaza[0].position.x, this.corpuriBaza[0].position.y + corpSize.y, this.corpuriBaza[0].position.z)

    this.scene.add(this.countertop);
  }

  resizeCountertop(){
    if (!this.corpuriBaza.length) { return }
    const leftMostObj = this.getMostLeftObject('baza')
    const rightMostObj = this.getMostRightObject('baza')
    const leftMostSize = this.getObjectSize(leftMostObj)
    const rightMostSize = this.getObjectSize(rightMostObj)
    const ctCurrentSize = this.getObjectSize(this.countertop)
    const newCtSizeZ = (leftMostObj.position.z + (leftMostSize.z / 2)) - (rightMostObj.position.z - (rightMostSize.z / 2))
    // console.log('[Engine Service] {resizeCountertop} ctCurrentSize.z', ctCurrentSize.z )
    // console.log('[Engine Service] {resizeCountertop} newCtSizeZ', newCtSizeZ )
    const newCtRatio = newCtSizeZ / leftMostSize.z
    this.countertop.scale.set(1, 1, newCtRatio)
    this.countertop.position.setZ( leftMostObj.position.z - ((leftMostObj.position.z - rightMostObj.position.z) / 2))
    // console.log('[Engine Service] {resizeCountertop} newCtRatio', newCtRatio )
  }





  changeAllObjectColors(){
    const self: EngineService = this
    this.corpuriSuspendate.forEach(
      (corp)=>{
        corp.children.forEach(
          (el)=>{
            if (el instanceof THREE.Mesh && (el.name.includes("_usa") || el.name.includes("_front") )) {
                el.material = self.createMaterial(self.culoareFrontSelectata.texture_url, 0.1, 1)
            }
            else if (el instanceof THREE.Mesh && el.name.includes("_sticla")) {
            }
            else if (el instanceof THREE.Mesh){
              el.material = self.createMaterial(self.culoareCorpSelectata.texture_url, 0.1, 1);
            }
          }
        )
      }
    )

    this.corpuriBaza.forEach(
      (corp)=>{
        corp.children.forEach(
          (el)=>{
            if (el instanceof THREE.Mesh && (el.name.includes("_usa") || el.name.includes("_front") )) {
                el.material = self.createMaterial(self.culoareFrontSelectata.texture_url, 0.1, 1)
            }
            else if (el instanceof THREE.Mesh && el.name.includes("_sticla")) {
            }
            else if (el instanceof THREE.Mesh){
              el.material = self.createMaterial(self.culoareCorpSelectata.texture_url, 0.1, 1);
            }
          }
        )
      }
    )

  }

  setWallsVisibleExcept(wallNo){
    // const wall = this.scene.getObjectByName( "wall2" )
    // // @ts-ignore
    // wall.material.transparent = true
    // // @ts-ignore
    // wall.material.opacity = 0

    for (let i = 1; i <= 4; i++) {
      const wall = this.scene.getObjectByName( "wall"+i )
      if (i !== wallNo) {
        wall['material']['transparent'] = false
        wall['material']['opacity'] = 1
      }
      else{
        console.log('hidding ' + i)
        //@ts-ignore
        wall.material.transparent = true
        //@ts-ignore
        wall.material.opacity = 0
      }
    }
  }

  generateNewObjectName(pos){
    let name = 'wrong'
    switch (pos) {
      case 'suspendat':
        if (this.corpuriSuspendate.length){
          let latestName = this.corpuriSuspendate[this.corpuriSuspendate.length-1].name
          let nr = parseInt(latestName.replace('corp-suspendat-', ''))
          nr++
          name = 'corp-suspendat-' + nr
        }
        else{
          name = 'corp-suspendat-0'
        }
        break

      case 'baza':
      if (this.corpuriBaza.length){
        let nr = this.corpuriBaza.length
        name = 'corp-baza-' + nr
      }
      else{
        name = 'corp-baza-0'
      }
      break

      case 'maner':
      if (this.manere.length){
        let nr = this.manere.length
        nr++
        name = 'maner-' + nr
      }
      else{
        name = 'maner-0'
      }
      break

      default:
        break;
    }

    return name
  }

  deleteObject(){
    if(this.corpuriBaza.includes(this.selectedObject)){
      const idx = this.corpuriBaza.indexOf(this.selectedObject)
      this.corpuriBaza.splice(idx, 1)
    }
    else{
      const idx = this.corpuriSuspendate.indexOf(this.selectedObject)
      this.corpuriSuspendate.splice(idx, 1)
    }
    this.scene.remove(this.selectedObject)
    this.contextMenu.next(false)
  }

  saveProject(){
    const project = {}
    project['date'] = new Date()
    project['room'] = {
      roomWidth : this.appService.roomWidth,
      roomLength: this.appService.roomLength,
      roomHeight: this.appService.roomHeight,
      wallColor: this.appService.wallColor,
      wallWidth: this.appService.wallWidth
    }
    project['objects'] = this.scene.toJSON()
    console.log('[Engine Service]{Save Project}', project)
    this.appService.saveProject(project)
  }

  zoomIn(){
    if (this.cameraZoom < 2.4) {
      this.cameraZoom += 0.2
      this.camera.zoom = this.cameraZoom
      this.camera.updateProjectionMatrix()
    }
  }

  zoomOut(){
    if (this.cameraZoom > 0.61) {
      this.cameraZoom -= 0.2
      this.camera.zoom = this.cameraZoom
      this.camera.updateProjectionMatrix()
    }
  }

  rotateSceneLeft(){
    this.camera.position.applyQuaternion( new THREE.Quaternion().setFromAxisAngle(
    new THREE.Vector3( 0, 1, 0 ), // The positive y-axis
        1.36 // The amount of rotation to apply this time
    ));

    this.camera.lookAt( this.scene.position );
    }

  rotateSceneRight(){
  console.log('[Engine service] rotate right')

  this.camera.position.applyQuaternion( new THREE.Quaternion().setFromAxisAngle(
  new THREE.Vector3( 0, 1, 0 ), // The positive y-axis
  -1.36 // The amount of rotation to apply this time
  ));

  this.camera.lookAt( this.scene.position );
  }

  // UTILS
  getObjectInfo(objName){
    return this.scene.getObjectByName(objName)
  }

  getObjectSize(obj){
    const bbox = new THREE.Box3().setFromObject(obj)
    let size = new THREE.Vector3
    size.setX(bbox.max.x - bbox.min.x)
    size.setY(bbox.max.y - bbox.min.y)
    size.setZ(bbox.max.z - bbox.min.z)
    return size
  }

  degrees_to_radians(degrees)
  {
    const pi = Math.PI;
    return degrees * (pi / 180);
  }
  // UTILS



}
