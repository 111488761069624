import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ui',
  templateUrl: './ui.component.html'
})
export class UiComponent implements OnInit {

  public constructor() {
  }

  public ngOnInit(): void {
  }

}
