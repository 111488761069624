import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from './../../services/auth.service';
import { RegisterComponent } from './../register/register.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  closeResult
  email
  password

  constructor(private modalService: NgbModal, private authservice: AuthService) { }

  ngOnInit(): void {

  }

  login(){
    this.authservice.signInRegular(this.email, this.password).then(
      (res)=>{
        alert('Bine ati venit!')
      }
    )
    .catch(
      (err)=>{
        alert(err)
      }
    )
  }

  openRegisterModal() {
    this.modalService.open(RegisterComponent, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed`;
    });
  }

}
