<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Proiectele mele</h4>
</div>
<div class="modal-body">
    <h3>Salveaza proiect existent</h3>
    <div class="form-check project-name">
      <ng-container *ngFor="let project of projectsList; let i = index">
          <input class="form-check-input" type="radio">
          <label class="form-check-label">
            {{project.name}}
          </label>
      </ng-container>
    </div>

    <br><br>
    sau
    <br>

    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">Proiect nou</span>
      <input type="text" class="form-control" placeholder="nume proiect" aria-label="nume nou proiect" >
    </div>

  <br><br>
  <button type="button" class="btn btn-primary">Salveaza</button>
  <button type="button" class="btn btn-secondary">Anuleaza</button>

</div>
<div class="modal-footer">

</div>
