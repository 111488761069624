<div class="engine-wrapper" id="wrapper">
  <canvas #rendererCanvas id="renderCanvas" (click)="engServ.onCanvasClickDown($event)"></canvas>

  <div class="row">
    <div class="col-4 col-md-4" style="text-align: center;">
      <button routerLink="/pas1" class="btn btn-arrow-left">
        <img src="/assets/imgs/arrow-left.png">
        {{'Pasul anterior' | translate}}
      </button>
    </div>
    <div class="col-4 col-md-4" id="bottom-controls" style="text-align: center;">

      <!-- <button (click)="rotateLeft()" class="btn btn-rotate-left">Stanga</button>
      <button (click)="rotateRight()" class="btn btn-rotate-right">Dreapta</button> -->
      <button (click)="zoomIn()" class="btn btn-zoom">
        <img src="/assets/ui/zoom-in.png" alt="zoom-in">
      </button>
      <button (click)="zoomOut()" class="btn btn-zoom">
        <img src="/assets/ui/zoom-out.png" alt="zoom-in">
      </button>
    </div>
    <div class="col-4 col-md-4" style="text-align: center;">
      <button routerLink="/pas3" class="btn btn-arrow-right">
        {{'Pasul urmator' | translate}}
        <img src="/assets/imgs/arrow-right.png">
      </button>
    </div>


    <!-- <div class="row">
      <div>
          zoom:
          <input type="number" [(ngModel)]="engServ.camera.zoom">
          cam x:
          <input type="number" [(ngModel)]="engServ.camera.position.x">
          cam y:
          <input type="number" [(ngModel)]="engServ.camera.position.y">
          cam z:
          <input type="number" [(ngModel)]="engServ.camera.position.z">
          cam rotation Y:
          <input type="number" [(ngModel)]="engServ.camera.rotation.y">
      </div>
    </div> -->




    <!-- <div class="col-md-4 offset-md-2" style="text-align: right;">
      <button (click)="showLeft()" class="btn btn-primary">Stanga</button>
    </div>
    <div class="col-md-4" style="text-align: left;">
      <button (click)="showRight()" class="btn btn-primary">Dreapta</button>
    </div>

    <div class="col-md-4 offset-md-4" style="text-align: center;">
      <button (click)="showBack()" class="btn btn-primary">Spate</button>
    </div> -->
  </div>

  <br>

</div>





