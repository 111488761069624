import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { parse } from 'node-html-parser'

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductInfoComponent implements OnInit {

  htmlSpecificatii

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getProductInfo()
  }

  // getProductInfo(){
  //   const token = 'f0nsf9be4bda3ceikd8llk5hjllvwh6m'
  //   let headers=new HttpHeaders();
  //   // headers.append("Authorization", 'Basic ' + btoa('homelux:h0melu8'))
  //     headers.append("Authorization", 'Basic aG9tZWx1eDpoMG1lbHU4')
  //     headers.append('Content-Type','application/x-www-form-urlencoded')
  //     // headers.append('Authorization','Bearer ' + token)

  //     this.http.get('https://staging.homelux.ro/rest/V1/categories',{
  //         headers:headers
  //     }).subscribe(
  //       (res)=>{
  //         console.log('[Product Info Component', res)
  //       }
  //     )
  // }

  async getProductInfo(){
    const response = await fetch('https://www.homelux.ro/corp-inferior-bucatarie-denisse-60-cm2-sertarerosualb-24101');
    const text = await response.text();
    const productInfo = parse(text);
    console.log('[Product Info Component] productInfo', productInfo)
    // this.htmlSpecificatii = productInfo.querySelector('.additional-attributes-wrapper').nextElementSibling.innerHTML;
    this.htmlSpecificatii = productInfo.querySelector('.additional-attributes-wrapper').innerHTML

    console.log('[Product Info Component] htmlSpecificatii', this.htmlSpecificatii)
  }

}
