import {Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './../../services/app-service.service';
import { EngineService } from './../../engine/engine.service';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ui-sidebar-pas2-left',
  templateUrl: './pas2-sidebar-left.component.html',
  styleUrls: ['./pas2-sidebar-left.component.scss']
})
export class Pas2SidebarLeftComponent implements OnInit {
  corpuriSuspendate
  corpuriBaza
  culoriFronturi
  culoriCorpuri
  culoriBlat
  manere
  faArrowCircleUp = faArrowCircleUp
  faArrowCircleDown = faArrowCircleDown
  panels = {
    generale: true,
    manere: false,
    suspendate: false,
    baza: false
  }

  public constructor(private httpClient: HttpClient, public appService: AppService, public engServ: EngineService) {
  }

  public ngOnInit(): void {
    this.httpClient.get("/assets/data/corpuri_suspendate.json").subscribe(data =>{
      this.corpuriSuspendate = data;
    })
    this.httpClient.get("/assets/data/corpuri_baza.json").subscribe(data =>{
      this.corpuriBaza = data;
    })
    this.httpClient.get("/assets/data/culori_fronturi.json").subscribe(data =>{
      this.culoriFronturi = data;
      this.engServ.culoareFrontSelectata = this.culoriFronturi[0]
    })
    this.httpClient.get("/assets/data/culori_corpuri.json").subscribe(data =>{
      this.culoriCorpuri = data;
      this.engServ.culoareCorpSelectata = this.culoriCorpuri[0]
    })
    this.httpClient.get("/assets/data/culori_blaturi.json").subscribe(data =>{
      this.culoriBlat = data;
      this.engServ.culoareBlatSelectata = this.culoriBlat[0]
    })
    this.httpClient.get("/assets/data/manere.json").subscribe(data =>{
      this.manere = data;
      this.engServ.manerSelectat = this.manere[0]
    })


  }

  addObject(corpId, pos){
    // console.log('[Pas2SideBar] {addObject} this.corpuriSuspendate.corpId', this.corpuriSuspendate[corpId])
    // const wall = this.getObjectInfo('wall2')
    if(pos == 'suspendat'){
      this.engServ.loadObject(this.corpuriSuspendate[corpId], pos)
    }
    else{
      this.engServ.loadObject(this.corpuriBaza[corpId], pos)
    }

  }

  selectCuloareCorpuri(culoare){
    this.engServ.culoareCorpSelectata = culoare
    this.engServ.changeAllObjectColors()
  }

  selectCuloareFronturi(culoare){
    this.engServ.culoareFrontSelectata = culoare
    this.engServ.changeAllObjectColors()
  }

  selectCuloareBlat(culoare){
    this.engServ.culoareBlatSelectata = culoare
    this.engServ.changeAllObjectColors()
  }

  selectManer(maner){
    this.engServ.manerSelectat = maner
  }

  getObjectInfo(objName){
    return this.engServ.scene.getObjectByName(objName)
  }

  togglePanel(name){
    if(this.panels[name] ){
      this.panels[name] = false
    }
    else{
      this.panels[name] = true
    }

  }



}
