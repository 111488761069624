import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './../../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  name
  email
  password

  constructor(private authService:AuthService, private modalService: NgbModal, private angularFirestore:AngularFirestore) { }

  ngOnInit(): void {
  }

  registerAccount(){
    if(this.name && this.email && this.password){
      this.authService.registerWithEmailAndPassword(this.email, this.password).then(
        (fUser)=>{
          if (fUser) {
            console.log('Register data OK!', fUser)
            this.angularFirestore.collection('users').doc(fUser.user.uid).set({name: this.name})
            this.modalService.dismissAll()
            alert('Bine ai venit pe platforma Homelux!')
          }
        }
      )
      .catch(
        (err)=>{
          alert(err)
        }
      )
    }
    else{
      alert('Te rugam sa completezi toate campurile!')
    }
  }
}
