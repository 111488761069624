<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Cont nou</h4>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
      <label for="dateOfBirth">Nume complet</label>
      <div class="input-group">
        <input id="username" class="form-control" required [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
      </div>

      <label for="dateOfBirth">Email</label>
      <div class="input-group">
        <input id="email" class="form-control" required [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
      </div>

      <label for="dateOfBirth">Parola</label>
      <div class="input-group">
        <input id="pass" type="password" class="form-control" required [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
      </div>

      <br>
      <button type="button" class="btn btn-primary" (click)="registerAccount()">Ma inregistrez</button>

      <br><br>
    </div>
  </form>
</div>
<div class="modal-footer">

</div>
