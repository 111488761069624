import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

import { AuthService } from './../services/auth.service';
import { LoginComponent } from './login/login.component';

@Component({
  selector: 'app-pas1',
  templateUrl: './pas1.component.html',
  styleUrls: ['./pas1.component.scss']
})
export class Pas1Component implements OnInit {
  closeResult = '';
  username
  password
  isLoggedIn = false

  constructor(private _firebaseAuth: AngularFireAuth, private modalService: NgbModal, public authService: AuthService ) { }

  ngOnInit(): void {
      this.authService.isLoggedIn().subscribe(
        (res)=>{
          if(!res){
            // this.openLoginModal()
          }
          else{
            this.isLoggedIn = true
            this.modalService.dismissAll()
          }
        }
      )

  }

  openLoginModal() {
    this.modalService.open(LoginComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false}).result
    .then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  signInRegular(email, password) {
      const credential = firebase.auth.EmailAuthProvider.credential( email, password );
  return this._firebaseAuth.signInWithEmailAndPassword(email, password)
  }



}
