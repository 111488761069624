import {Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './../../services/app-service.service';

@Component({
  selector: 'app-ui-sidebar-left',
  templateUrl: './pas1-sidebar-left.component.html'
})
export class Pas1SidebarLeftComponent implements OnInit {
  optiuni_podea
  optiuni_pereti

  public constructor(private httpClient: HttpClient, public appService: AppService) {
  }

  public ngOnInit(): void {
    this.httpClient.get("/assets/data/optiuni_podea.json").subscribe(data =>{
      this.optiuni_podea = data;
    })
    this.httpClient.get("/assets/data/optiuni_pereti.json").subscribe(data =>{
      this.optiuni_pereti = data;
    })
  }

  changeWallColor(color){
    this.appService.wallColor = color
  }

  changeFloorTexture(texture){
    this.appService.floorTexture = texture
  }

}
