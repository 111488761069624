<div class="row top-bar">
  <!-- TODO: remove in production -->
  <!-- <div style="position: absolute; top: 1em; right: 1em; max-height: 20em; width: 20em; overflow-y: auto; background-color: rgba(0, 0, 0, 0.3); color: #fff; padding: 0.5%; z-index: 999;">
    <h4>Helper</h4>
    <pre>
      {{engServ.helpCoords | json}}
    </pre>
  </div> -->
  <!-- TODO: remove in production -->

  <div class="context-menu" *ngIf="ctxMenu?.status" [style.top.px]="ctxMenu?.position.y" [style.left.px] = "ctxMenu?.position.x">
    <button (click)="showProductInfo()"> <fa-icon [icon]="faInfoCircle" class="arrow-icon"></fa-icon> {{'informatii produs' | translate}}</button>
    <br>
    <button (click)="deleteObject()"> <fa-icon [icon]="faTrash" class="arrow-icon"></fa-icon> {{'sterge' | translate}}</button>
  </div>

  <div class="col-2">
    <img src="/assets/logo.jpg" style="width: 100%; height: auto;">
  </div>

  <div class="col-5 col-md-6" style="background-color: #fff;">
    <div class="row steps" style="padding-top: 1.5%;">
      <div class="col-3 step-container" style="text-align: center;" [ngClass]="{'active': currentPage === '/pas1'}">
        <a style="text-align: center;" routerLink="/pas1">
          <div class="step">1</div>
          <span class="step-text">{{'Camera' | translate}}</span>
        </a>
      </div>
      <div class="col-3 step-container" style="text-align: center;" [ngClass]="{'active': currentPage === '/pas2'}">
        <a style="text-align: center;" routerLink="/pas2">
          <div class="step">2</div>
          <span class="step-text">{{'Mobilier' | translate}}</span>
        </a>
      </div>
      <div class="col-3 step-container" style="text-align: center;" [ngClass]="{'active': currentPage === '/pas3'}">
        <a style="text-align: center;" routerLink="/pas3">
          <div class="step">3</div>
          <span class="step-text">{{'Accesorii' | translate}}</span>
        </a>
      </div>
      <div class="col-3 step-container" style="text-align: center;" [ngClass]="{'active': currentPage === '/pas4'}">
        <a style="text-align: center;" routerLink="/pas4">
          <div class="step">4</div>
          <span class="step-text">{{'Comanda' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="col-5 col-md-2" style="text-align: center;">
    <select [(ngModel)]="appService.currentLanguage" (change)="changeLanguage()" class="language-switcher">
      <!-- <option *ngFor="let country of countries" [value]="country.id | lowercase">{{country.id}}</option> -->
      <option value="en">EN</option>
      <option value="ro">RO</option>
      <option value="de">DE</option>
    </select>

    <button class="top-btn" (click)="saveProject()">
      <fa-icon [icon]="faSave"></fa-icon>
      <ng-template *ngIf="!isMobile">{{'Salveaza' | translate}}</ng-template>
    </button>
    <button class="top-btn">
      <fa-icon [icon]="faFileAlt"></fa-icon>
      <ng-template *ngIf="!isMobile">{{'Proiectele mele' | translate}}</ng-template>
    </button>

    <button class="logout-btn" *ngIf="isLoggedIn" (click)="logout()">
      <ng-template *ngIf="!isMobile">{{'Salut' | translate}} {{userDetails?.name}}<br></ng-template>
      <fa-icon [icon]="faSignOutAlt"></fa-icon>
      <ng-template *ngIf="!isMobile">{{'Salut' | translate}}<span class="logout-link">Logout</span></ng-template>
    </button>
  </div>
</div>
