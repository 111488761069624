<div class="ui-wrapper container-fluid">
  <div class="row">
    <div class="col-md-12">
      <app-ui-infobar-top></app-ui-infobar-top>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <app-ui-sidebar-left></app-ui-sidebar-left>
    </div>
  </div>
  
  
  <app-ui-sidebar-right></app-ui-sidebar-right>
  <app-ui-infobar-bottom></app-ui-infobar-bottom>
</div>
