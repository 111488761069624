<div class="ui-wrapper">
    <div class="row">
      <div class="col-md-12">
        <app-ui-infobar-top></app-ui-infobar-top>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" style="text-align: center;">
          <h1>{{'Adauga accesorii' | translate}}</h1>
          <div class="row">
            <div class="col-md-2 offset-md-1">
              <div class="options-title">
                  {{'Manere' | translate}}
              </div>
              <div class="row acc-container">
                <ng-container *ngFor="let maner of manere | keyvalue" >
                    <div class="col-md-2 sample"
                            style="background-image: url('/assets/accesorii/manere/{{maner.value.preview}}'); "
                    >
                    </div>
                </ng-container>
              </div>
            </div>

            <div class="col-md-2">
              <div class="options-title">
                  {{'Alte accesorii' | translate}}
              </div>
              <div class="row acc-container">
                <ng-container *ngFor="let accesoriu of alteAccesorii | keyvalue" >
                    <div class="col-md-12 sample"
                            style="background-image: url('/assets/accesorii/alte_accesorii/{{accesoriu.value.preview}}'); "
                    >
                    </div>
                </ng-container>
              </div>
            </div>

            <div class="col-md-2">
              <div class="options-title">
                  {{'Frigidere' | translate}}
              </div>
              <div class="row acc-container">
                <ng-container *ngFor="let frigider of frigidere | keyvalue" >
                    <div class="col-md-12 sample"
                            style="background-image: url('/assets/accesorii/frigidere/{{frigider.value.preview}}'); "
                    >
                    </div>
                </ng-container>
              </div>
            </div>

            <div class="col-md-2">
              <div class="options-title">
                  {{'Aragaze' | translate}}
              </div>
              <div class="row acc-container">
                <ng-container *ngFor="let aragaz of aragaze | keyvalue" >
                    <div class="col-md-12 sample"
                            style="background-image: url('/assets/accesorii/aragaze/{{aragaz.value.preview}}'); "
                    >
                    </div>
                </ng-container>
              </div>
            </div>

            <div class="col-md-2">
              <div class="options-title">
                  {{'Hote' | translate}}
              </div>
              <div class="row acc-container">
                <ng-container *ngFor="let hota of hote | keyvalue" >
                    <div class="col-md-12 sample"
                            style="background-image: url('/assets/accesorii/hote/{{hota.value.preview}}'); "
                    >
                    </div>
                </ng-container>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-md-4" style="text-align: center;">
              <button routerLink="/pas2" class="btn btn-arrow-left">
                <img src="/assets/imgs/arrow-left.png">
                {{'Pasul anterior' | translate}}
              </button>
            </div>
            <div class="col-md-4" style="text-align: center;">
            </div>
            <div class="col-md-4" style="text-align: center;">
              <button routerLink="/pas4" class="btn btn-arrow-right">
                {{'Pasul urmator' | translate}}
                <img src="/assets/imgs/arrow-right.png">
              </button>
            </div>
          </div>

      </div>
    </div>

    <div class="row footer">
      <div class="col-md-12">
        <app-ui-infobar-bottom></app-ui-infobar-bottom>
      </div>
    </div>
</div>
