<div class="ui-wrapper">
    <div class="row">
      <div class="col-md-12">
        <app-ui-infobar-top></app-ui-infobar-top>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" style="text-align: center;">
        <h1>{{'Plaseaza comanda' | translate}}</h1>

        <div class="row">
          <div class="col-md-4" style="text-align: center;">
            <button routerLink="/pas3" class="btn btn-arrow-left">
              <img src="/assets/imgs/arrow-left.png">
              {{'Pasul anterior' | translate}}
            </button>
          </div>
          <div class="col-md-4" style="text-align: center;">
          </div>
          <div class="col-md-4" style="text-align: center;">
          </div>
        </div>

      </div>
    </div>

    <div class="row footer">
      <div class="col-md-12">
        <app-ui-infobar-bottom></app-ui-infobar-bottom>
      </div>
    </div>
</div>
