import { Component, OnInit } from '@angular/core';
import { AppService } from './../../services/app-service.service';

@Component({
  selector: 'app-room-config',
  templateUrl: './room-config.component.html',
  styleUrls: ['./room-config.component.scss']
})
export class RoomConfigComponent implements OnInit {
  roomWidth
  roomLength

  constructor(public appService: AppService) {
      this.roomWidth = appService.roomWidth
      this.roomLength = appService.roomLength

   }

  ngOnInit(): void {
  }

  changeSize(){
    this.appService.roomWidth = this.roomWidth
  }

}
