import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard'

import { RouterModule, Routes } from '@angular/router';
import { Pas1Component } from './pas1/pas1.component';
import { Pas2Component } from './pas2/pas2.component';
import { Pas3Component } from './pas3/pas3.component';
import { Pas4Component } from './pas4/pas4.component';


const routes: Routes = [
  { path: '', component: Pas1Component },
  { path: 'pas1', component: Pas1Component },
  { path: 'pas2', component: Pas2Component, canActivate: [AuthGuard]  },
  { path: 'pas3', component: Pas3Component, canActivate: [AuthGuard] },
  { path: 'pas4', component: Pas4Component, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }