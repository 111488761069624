import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EngineService} from './engine.service';
import { AppService } from './../services/app-service.service';
import * as THREE from 'three';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['engine.component.scss']
})
export class EngineComponent implements OnInit {
  currentWall = 4

  @ViewChild('rendererCanvas', {static: true})
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  public constructor(public engServ: EngineService, private appService: AppService) {
  }

  public ngOnInit(): void {
    this.engServ.createScene(this.rendererCanvas);
    this.engServ.animate();
  }

  // goRight(){
  //   this.currentWall ++
  //   if (this.currentWall > 4) {
  //     this.currentWall = 1
  //   }
  //   let wall

  //   switch (this.currentWall) {
  //     case 1: // fata
  //       this.engServ.setWallsVisibleExcept(1)
  //       this.engServ.moveCamera(new THREE.Vector3( 0, this.appService.roomHeight/2, 0 - this.appService.roomWidth ), 0 )
  //       break;
  //     case 2: // dreapta
  //       this.engServ.setWallsVisibleExcept(2)
  //       this.engServ.moveCamera(new THREE.Vector3( 0 - this.appService.roomLength, this.appService.roomHeight/2, 0 ), 1.6 )
  //       break;
  //     case 3: // spate
  //       this.engServ.setWallsVisibleExcept(3)
  //       this.engServ.moveCamera(new THREE.Vector3( 0, 0 - this.appService.roomHeight/2, this.appService.roomWidth * 0.8), 3.2 )
  //       break;
  //     case 4:  //stanga
  //       this.engServ.setWallsVisibleExcept(4)
  //       this.engServ.moveCamera(new THREE.Vector3( this.appService.roomLength, this.appService.roomHeight/2, 0 ), -1.6 )
  //       break;

  //     default:
  //       break;
  //   }


  // }

//   case 1: // fata
//   this.engServ.setWallsVisibleExcept(1)
//   this.engServ.moveCamera(new THREE.Vector3( 0, this.appService.roomHeight * 0.5, 0 - this.appService.roomWidth * 0.8), 0 )
//   break;
// case 2: // dreapta
//   this.engServ.setWallsVisibleExcept(2)
//   this.engServ.moveCamera(new THREE.Vector3( 0 - this.appService.roomLength, this.appService.roomHeight * 0.5, 0 ), 1.6 )
//   break;
// case 3: // spate
//   this.engServ.setWallsVisibleExcept(3)
//   this.engServ.moveCamera(new THREE.Vector3( 0, this.appService.roomHeight * 0.5, 0 - this.appService.roomWidth * 0.8), 3.2 )
//   break;
// case 4:  //stanga
//   this.engServ.setWallsVisibleExcept(4)
//   this.engServ.moveCamera(new THREE.Vector3( this.appService.roomLength, this.appService.roomHeight * 0.5, 0 ), -1.6 )
//   break;

rotateLeft(){
  this.engServ.rotateSceneLeft();
  this.currentWall ++
    if (this.currentWall > 4) {
      this.currentWall = 1
    }
  this.hideFrontWall()
}

rotateRight(){
  this.engServ.rotateSceneRight();
  this.currentWall --
    if (this.currentWall < 1) {
      this.currentWall = 4
    }
  this.hideFrontWall()
}

hideFrontWall(){
  switch (this.currentWall) {
    case 1: // fata
      this.engServ.setWallsVisibleExcept(1)
      break;
    case 2: // dreapta
      this.engServ.setWallsVisibleExcept(2)
      break;
    case 3: // spate
      this.engServ.setWallsVisibleExcept(3)
      break;
    case 4:  //stanga
      this.engServ.setWallsVisibleExcept(4)
      break;

    default:
      break;
  }
}


zoomIn(){
  this.engServ.zoomIn()
}

zoomOut(){
  this.engServ.zoomOut()
}


// showFront(){
//   // this.engServ.setWallsVisibleExcept(1)
//   // this.engServ.moveCamera(new THREE.Vector3( 0, this.appService.roomHeight/2, 0 - this.appService.roomWidth ), 0 )
// }

//   showLeft(){
//     this.engServ.setWallsVisibleExcept(4)
//   this.engServ.moveCamera(new THREE.Vector3( this.appService.roomLength, this.appService.roomHeight * 0.5, 0 ), -1.6 )


//   }

//   showRight(){
//     this.engServ.setWallsVisibleExcept(2)
//   this.engServ.moveCamera(new THREE.Vector3( 0 - this.appService.roomLength, this.appService.roomHeight * 0.5, 0 ), 1.6 )

//   }

//   showBack(){
//     this.engServ.setWallsVisibleExcept(3)
//   this.engServ.moveCamera(new THREE.Vector3( 0, this.appService.roomHeight * 0.5, 0 - this.appService.roomWidth * 0.8), 3.2 )

//   }







  rotateCameraLeft(){
    this.engServ.camera.rotateY(this.engServ.camera.rotation.y - this.degrees_to_radians(5))
    // this.engServ.camera.position.setX(this.engServ.camera.position.x - 5)

    const wall1 = this.engServ.scene.getObjectByName( "wall1" )
    this.engServ.camera.lookAt(0,0,0)
    // this.engServ.camera.rotateY(this.degrees_to_radians(270))
    // this.engServ.camera.position.setX(this.appService.roomWidth / 2)
  }

  // UTILS
  getObjectInfo(objName){
    return this.engServ.scene.getObjectByName(objName)
  }

  degrees_to_radians(degrees)
  {
    const pi = Math.PI;
    return degrees * (pi / 180);
  }
  // UTILS

}
