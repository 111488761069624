import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { ProjectsListComponent } from './../ui/projects-list/projects-list.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public roomWidth = 300
  public roomLength = 600
  public roomHeight = 262
  public inaltimeCorpuriSuspendate = 150
  public wallColor = 'raffia.jpg'
  public wallWidth = 20
  public floorTexture = '/floors/parchet-stejar-alb.jpg'
  public currentLanguage = 'ro'

  constructor(public firestore: AngularFirestore, public auth: AngularFireAuth, public authService: AuthService, public afStorage: AngularFireStorage,
              private modalService: NgbModal
    ) {
   }

  saveProject(data){
    console.log('[App service] {saveProject} data', data)
    let user = this.authService.getUser()
    const folderRef = this.afStorage.ref('/projects/'+user.uid+'/')
    folderRef.listAll().subscribe(
      (res)=>{
        console.log('Folder results', res)
        this.modalService.open(ProjectsListComponent, {ariaLabelledBy: 'modal-basic-title'}).result
          .then((result) => {
            // this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          })
        if (res.items.length = 0 ) {

        }

      }
    )
    // this.firestore.collection('users').doc(user.uid).collection('projects').add(data)
    // .then(
    //   (res)=>{
    //     alert('Proiectul a fost salvat!')
    //   }
    // )
    // .catch(
    //   (err)=>{
    //     alert('Proiectul nu a putut fi salvat. Te rugam sa incerci din nou.')
    //     console.log('Error saving project', err)
    //   }
    // )
  }

  saveFile(data){
    let user = this.authService.getUser()
    const fileRef = this.afStorage.ref('/projects/'+user.uid+'/' +'test.json');
    const jsonString = JSON.stringify(data);
    const blob = new Blob([jsonString], { type: 'application/json' });
    fileRef.put(blob).then(
      (res)=>{
        alert('Proiectul a fost salvat!')
      }
    )
    .catch(
      (err)=>{
        alert('Proiectul nu a putut fi salvat. Te rugam sa incerci din nou.')
        console.log('Error saving project', err)
      }
    )
  }
}
