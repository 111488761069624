<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Intra in cont</h4>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
      <label for="email">Email</label>
      <div class="input-group">
        <input id="email" class="form-control" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" >
      </div>

      <label for="pass">Password</label>
      <div class="input-group">
        <input id="pass" type="password" class="form-control" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
      </div>

      <br>
      <button type="button" class="btn btn-primary" (click)="login()">Login</button>

      <br><br><br>
      Nu ai cont? Creaza-ti contul gratuit aici: <br>
      <button type="button" class=" btn-outline-dark" (click)="openRegisterModal()">Inregistrare</button>
      <br><br>
    </div>
  </form>
</div>
<div class="modal-footer">

</div>
